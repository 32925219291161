<template>
  <mobile-screen :header="true" screen-class="gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_manage-users' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("manage-users", "add-user", "add-user") }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      @submit="submitForm"
      v-slot="{ errors }"
      class="form"
      autocomplete="off"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.email }"
        >
          <span class="error-message" v-if="errors.email">
            {{ errors.email }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="emailId">
                {{ displayLabelName("manage-users", "add-user", "email") }}
                <span>*</span></label
              >
              <Field
                name="email"
                as="input"
                type="email"
                rules="required|email"
                id="emailId"
                :disabled="submitting"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.first_name }"
        >
          <span class="error-message" v-if="errors.first_name">
            {{ errors.first_name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="firstNameId">
                {{ displayLabelName("manage-users", "add-user", "first-name") }}
                <span>*</span></label
              >
              <Field
                name="first_name"
                as="input"
                type="text"
                rules="required"
                id="firstNameId"
                :disabled="submitting"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.last_name }"
        >
          <span class="error-message" v-if="errors.last_name">
            {{ errors.last_name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="lastNameId">
                {{ displayLabelName("manage-users", "add-user", "last-name") }}
                <span>*</span></label
              >
              <Field
                name="last_name"
                as="input"
                type="text"
                rules="required"
                id="lastNameId"
                :disabled="submitting"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.middleName"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="middleNameId">
                {{
                  displayLabelName("manage-users", "add-user", "middle-name")
                }}</label
              >
              <Field
                name="middle_name"
                as="input"
                type="text"
                :disabled="submitting"
                id="middleNameId"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.phone }"
        >
          <span class="error-message" v-if="errors.phone">
            {{ errors.phone }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="phoneId">
                {{ displayLabelName("manage-users", "add-user", "phone") }}
                <span>*</span></label
              >
              <Field
                name="phone"
                as="input"
                type="tel"
                rules="required"
                id="phoneId"
                autocomplete="off"
                :disabled="submitting"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.username }"
        >
          <span class="error-message" v-if="errors.username">
            {{ errors.username }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="username">
                {{ displayLabelName("manage-users", "add-user", "username") }}
                <span>*</span></label
              >
              <Field
                name="username"
                id="username"
                as="input"
                type="text"
                rules="required"
                autocomplete="off"
                :disabled="submitting"
              />
            </div>
          </div>
        </li>
        <template v-if="!hasSso">
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.password }"
          >
            <span class="error-message" v-if="errors.password">
              {{ errors.password }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="newPasswordId">
                  {{
                    displayLabelName("manage-users", "add-user", "new-password")
                  }}
                  <span>*</span></label
                >
                <Field
                  name="password"
                  as="input"
                  type="password"
                  id="newPasswordId"
                  rules="required|min:6"
                  autocomplete="new-password"
                  :disabled="submitting"
                />
              </div>
            </div>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.password_confirmation }"
          >
            <span class="error-message" v-if="errors.password_confirmation">
              {{ errors.password_confirmation }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label"
                  for="confirmPasswordId"
                >
                  {{
                    displayLabelName(
                      "manage-users",
                      "add-user",
                      "confirm-new-password"
                    )
                  }}
                  <span>*</span></label
                >
                <Field
                  name="password_confirmation"
                  as="input"
                  type="password"
                  rules="required|confirmed:@password"
                  id="confirmPasswordId"
                  :disabled="submitting"
                />
              </div>
            </div>
          </li>
        </template>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.city"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="cityId">{{
                displayLabelName("manage-users", "add-user", "city")
              }}</label>
              <Field name="city" as="input" type="text" id="cityId" />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.company"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="companyId">{{
                displayLabelName("manage-users", "add-user", "company")
              }}</label>
              <Field name="company" as="input" type="text" id="companyId" />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.department"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="departmentId">{{
                displayLabelName("manage-users", "add-user", "department")
              }}</label>
              <Field
                name="department"
                as="input"
                type="text"
                id="departmentId"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.passport"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="passportId">{{
                displayLabelName("manage-users", "add-user", "passport")
              }}</label>
              <Field name="passport" as="input" type="text" id="passportId" />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.licensePlate"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="licensePlateId">{{
                displayLabelName("manage-users", "add-user", "license-plate")
              }}</label>
              <Field
                name="license_plate"
                as="input"
                type="text"
                id="licensePlateId"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.costCode"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="licensePlateId">{{
                displayLabelName("manage-users", "add-user", "cost-code")
              }}</label>
              <Field name="cost_code" as="input" type="text" id="costCodeId" />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.projectCostCode"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="licensePlateId">{{
                displayLabelName(
                  "manage-users",
                  "add-user",
                  "project-cost-code"
                )
              }}</label>
              <Field
                name="project_cost_code"
                as="input"
                type="text"
                id="projectCostCodeId"
              />
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <!---------------------------------->
        <!-- LANGUAGE - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.language_id }"
          v-if="userFormFields.language"
        >
          <span class="error-message" v-if="errors.language_id">
            {{ errors.language_id }}
          </span>
          <div
            @click="openLanguage(language, 'r_add-user-language')"
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <div class="clebex-section-input-label" style="cursor: pointer;">
                {{ displayLabelName("manage-users", "add-user", "language") }}
              </div>
              <p class="text-label" style="cursor: pointer;">
                {{ language ? language.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="16"
                  height="16"
                />
              </span>
            </span>
          </div>
          <Field
            name="language_id"
            as="input"
            type="hidden"
            rules="required"
            :disabled="submitting"
            v-model="language"
          >
          </Field>
        </li>
        <!---------------------------------->
        <!-- LANGUAGE - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- TIMEZONE - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.timezone_id }"
          v-if="userFormFields.timezone"
        >
          <span class="error-message" v-if="errors.timezone_id">
            {{ errors.timezone_id }}
          </span>
          <div
            @click="openTimezone(timezone, 'r_add-user-timezone')"
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <div class="clebex-section-input-label" style="cursor: pointer;">
                {{ displayLabelName("manage-users", "add-user", "timezone") }}
              </div>
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{
                  timezone
                    ? timezone.name
                        .replace(/ \*/g, ", ")
                        .replace("UTC ", "UTC+")
                    : ""
                }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="16"
                  height="16"
                />
              </span>
            </span>
          </div>
          <Field
            name="timezone_id"
            as="input"
            type="hidden"
            rules="required"
            :disabled="submitting"
            v-model="timezone"
          >
          </Field>
        </li>
        <!---------------------------------->
        <!-- TIMEZONE - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- COUNTRY - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.country_id }"
          v-if="userFormFields.country"
        >
          <span class="error-message" v-if="errors.country_id">
            {{ errors.country_id }}
          </span>
          <div
            @click="openCountry(country, 'r_add-user-country')"
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <div class="clebex-section-input-label" style="cursor: pointer;">
                {{ displayLabelName("manage-users", "add-user", "country") }}
              </div>
              <p class="text-label" style="cursor: pointer;">
                {{ country ? country.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="16"
                  height="16"
                />
              </span>
            </span>
          </div>
          <Field
            name="country_id"
            as="input"
            type="hidden"
            rules="required"
            :disabled="submitting"
            v-model="country"
          >
          </Field>
        </li>
        <!---------------------------------->
        <!-- COUNTRY - END -->
        <!---------------------------------->
      </ul>
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.sso"
        >
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="userSsoCheckbox"
                    type="checkbox"
                    v-model="hasSso"
                    :disabled="submitting"
                  />
                  <label for="userSsoCheckbox"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("manage-users", "add-user", "sso") }}
              </dd>
            </dl>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.visitor"
        >
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="userIsVisitorCheckbox"
                    type="checkbox"
                    v-model="isVisitor"
                    :disabled="submitting"
                  />
                  <label for="userIsVisitorCheckbox"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("manage-users", "add-user", "is-visitor") }}
              </dd>
            </dl>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="userFormFields.addAssignableContainersToUser"
        >
          <router-link
            :to="{ name: 'r_add-user-add-containers-to-user' }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedContainers && selectedContainers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "manage-users",
                    "add-user",
                    "add-assignable-containers-to-user"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedContainers.map(item => `${item.name}`).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "manage-users",
                  "add-user",
                  "add-assignable-containers-to-user"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <button
        type="submit"
        ref="submitProfileForm"
        style="display: none"
      ></button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState } from "vuex";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import { errorHandler } from "@/services/error-handler";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "AddUser",
  data() {
    return {
      submitting: false,
      hasSso: false,
      isVisitor: false
    };
  },
  created() {
    this.setSelectedLanguage(null);
    this.setSelectedTimezone(null);
    this.setSelectedCountry(null);
  },
  computed: {
    ...mapState("user", [
      "userId",
      "selectedContainers",
      "userFormFields",
      "company"
    ]),
    ...mapState("language", ["selectedLanguage"]),
    ...mapState("country", ["selectedCountry"]),
    ...mapState("timezone", ["selectedTimezone"]),
    language() {
      if (this.selectedLanguage) {
        return this.selectedLanguage;
      } else {
        if (this.company && this.company.data) {
          return this.getLanguage(this.company.data.language_id);
        }
        return null;
      }
    },
    timezone() {
      if (this.selectedTimezone) {
        return this.selectedTimezone;
      } else {
        if (this.company && this.company.data) {
          return this.getTimezone(this.company.data.timezone_id);
        }
        return null;
      }
    },
    country() {
      if (this.selectedCountry) {
        return this.selectedCountry;
      } else {
        if (this.company && this.company.data) {
          return this.getCountry(this.company.data.country_id);
        }
        return null;
      }
    }
  },
  methods: {
    ...mapActions("user", ["getMasterUserList", "getCompanyUserList"]),
    ...mapActions("language", ["setSelectedLanguage"]),
    ...mapActions("timezone", ["setSelectedTimezone"]),
    ...mapActions("country", ["setSelectedCountry"]),
    ...mapActions("pagination", ["setPaginationData"]),
    submitForm(values, { resetForm }) {
      if (values) {
        const subdomain = getSubdomain();

        values.salutation_id = 1;
        values.is_active = 1;
        values.sso = Number(this.hasSso);
        values.is_visitor = Number(this.isVisitor);
        values.language_id = this.language.id;
        values.country_id = this.country.id;
        values.timezone_id = this.timezone.id;

        if (subdomain) {
          values.pin = "pin"; // TODO remove pin field from here when backend finishes
        }

        if (this.selectedContainers && this.selectedContainers.length) {
          values.container_ids = this.selectedContainers.map(item => item.id);
        }

        this.submitting = true;
        if (subdomain) {
          this.submitCompanyData(values, resetForm);
        } else {
          this.submitMasterData(values, resetForm);
        }
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      let success = false;
      httpServiceAuth
        .post(apiEndpoints.master.users, values)
        .then(() => {
          resetForm();
          this.hasSso = false;
          this.isVisitor = false;
          this.getMasterUserList();
          this.setSelectedLanguage(null);
          this.setSelectedTimezone(null);
          this.setSelectedCountry(null);
          success = true;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          if (success) {
            this.$router.push({ name: "r_manage-users" });
          }
        });
    },
    submitCompanyData(values, resetForm) {
      this.setPaginationData(null);
      if (!values.pin) {
        values.pin = "Pin"; // TODO remove pin field from here when backend finishes
      }
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      let success = false;
      httpServiceAuth
        .post(apiEndpoints.company.users, values)
        .then(() => {
          resetForm();
          this.hasSso = false;
          this.isVisitor = false;
          this.getCompanyUserList();
          this.setSelectedLanguage(null);
          this.setSelectedTimezone(null);
          this.setSelectedCountry(null);
          success = true;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.submitting = false;
          if (success) {
            this.$router.push({ name: "r_manage-users" });
          }
        });
    },
    initSubmit() {
      this.$refs.submitProfileForm.click();
    }
  },
  beforeUnmount() {
    this.$store.commit("user/setAssignableContainers", null, { root: true });
    this.$store.commit("user/setSelectedContainers", null, { root: true });
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [languagesMixin, timezonesMixin, countriesMixin]
};
</script>
